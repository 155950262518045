<template>
    <div>
        <v-card class="elevation-4 pa-0" style="border-radius: 7px" >
            <v-card-text class="pa-2" style="font-size: 12px;">
                <v-layout row wrap text-xs-center>
                    <v-flex xs10 sm5 md5 >
                        <v-text-field class="pa-0 mt-3"
                            id="start_target_date"
                            label="Select Start Date"
                            type="date" v-model="startTargetDate"
                            color="teal"
                        ></v-text-field>
                    </v-flex>
                    <v-flex xs2 sm1>
                    </v-flex>
                    <v-flex xs10 sm5 md5>
                        <v-text-field class="pa-0 mt-3"
                            id="end_target_date"
                            label="Select End Date"
                            type="date" v-model="endTargetDate"
                            color="teal"
                        ></v-text-field>
                    </v-flex>
                    <v-flex xs2 sm1 md1>
                        <v-btn fab primary color="teal darken-2" class="my-3" @click="getCampaignTransactions()" style="height: 30px; width: 30px" >
                            <i class="fas fa-search white--text"> </i>                            
                        </v-btn>
                    </v-flex>
                </v-layout>
            </v-card-text>
        </v-card>

        <div class="elevation-4 white lighten-4 mt-3 pa-2" style="max-height: 400px; overflow: auto; border-radius: 10px"> 
            <h3 class="dark--text font-weight-bold text-xs-center" id="">({{campaignTransactions.length}}) {{transaction_heading}}</h3>
            <v-divider></v-divider>
            <div color="white" style="" v-for="item in campaignTransactions" :key="item.key">
                <v-card-text class="pa-1 caption" style="">
                    <v-layout row wrap>
                        <v-flex xs6 text-xs-left>
                            <div v-if="item.withdrawal" >
                                <span class="dark--text font-weight-bold">Withdrawal:</span><span> {{item.amount}} FCFA</span>
                            </div>
                            <div v-else>
                                <span class="dark--text font-weight-bold">Deposit:</span><span> {{item.amount}} FCFA</span>
                            </div>
                        </v-flex>
                        <v-flex xs6 text-xs-right>
                            <span class="dark--text font-weight-bold">Date:</span><span> {{item.get_day_month_year_str}}</span>
                        </v-flex>
                        <v-flex xs8 text-xs-left>
                            <span class="dark--text font-weight-bold ">By:</span><span> {{item.top_up_admin_username}} - {{item.top_up_admin_phone_number}}</span>
                        </v-flex>
                        <v-flex xs4 text-xs-right>
                            <span class="dark--text font-weight-bold ">at</span><span> {{item.transaction_time}}</span>
                        </v-flex>
                    </v-layout>
                </v-card-text>
                <v-divider></v-divider>
            </div>

            <div class="text-xs-right" v-if="campaignTransactions.length > 25">
                <v-pagination :length="6" v-model="page" :total-visible="4" circle></v-pagination>
            </div>
       </div>
    </div>
</template>

<script>
    import axios from 'axios'

    export default {
        data() {
            return {
                page: 1,
                startTargetDate: null,
                endTargetDate: null,
                transaction_heading: "All Transaction History",
                campaignTransactions: [],
            }
        },

        methods:{
            async getCampaignTransactions(){
                this.$store.commit('setIsLoading', true)

                const campaign_category = this.$route.params.category  // get campaign category from url and send request to server
                const start_date = this.startTargetDate  //
                const end_date = this.endTargetDate // 
                await axios
                    .get('/api/v1/savings/get/user/transactions/'+start_date+'/'+end_date+'/'+campaign_category+'/')
                    .then(response => {
                        this.campaignTransactions = response.data  // get the data and fill into campaigns
                        console.log(this.campaignTransactions )
                        if (campaign_category === 'all'){
                            this.transaction_heading = "All Transactions"
                            if (start_date || end_date){
                                this.transaction_heading = "Selected Transactions"
                            } 
                        }
                        else if (campaign_category === 'deposits'){
                            this.transaction_heading = "Deposited Transactions"
                            if (start_date || end_date){
                                this.transaction_heading = "Selected Deposited Transactions"
                            } 
                        }
                        else if (campaign_category === 'withdrawals'){
                            this.transaction_heading = "Withdrawn Transactions"
                            if (start_date || end_date){
                                this.transaction_heading = "Selected Withdrawn Transactions"
                            } 
                        }
                    })
                    .catch(error => {
                        if (error.response){
                            this.$store.commit('setSnackBarMessage', error.response.status + " " + error.response.statusText)
                            this.$store.commit('setSnackBarColor', "red darken-4")
                            this.$store.commit('activateSnackBar', true)
                        }else{
                            this.$store.commit('setSnackBarMessage', "An error occured, please check your internet connection and try again later.")
                            this.$store.commit('setSnackBarColor', "red darken-4")
                            this.$store.commit('activateSnackBar', true)
                        }
                    })
                this.$store.commit('setIsLoading', false)
            },
        },
        
        mounted(){
            // executes these after the page has been mounted
            document.title = "PettyCash | Campaign Detail Info."
            this.$store.commit('setCloseURL', "/savings")
        },

        watch: {
            '$route': function(to, from) {
                this.getCampaignTransactions()
            }
        },

        mounted(){
            // executes these after the page has been mounted
            this.getCampaignTransactions();
        },

    }
</script>

<style >
    .v-text-field__details{
        display: none!important;
    }
</style>